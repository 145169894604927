import React, { useState } from "react";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { FaRegTrashCan } from "react-icons/fa6";
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/category.png';


const CategoriesList = () => {
  // State for search input value
  const navigate = useNavigate();
  const { categories } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const deleteCategory = async (id) => {
    let sweetAlertClass = new SweetAlertComponent();
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/category/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "", "Categories Deleted Successfully");
        window.location.reload();
      } else {
        const data = await response.json();
        sweetAlertClass.showSweetAlert(2, "", "Error Happened While Deleting Categories");
      }
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "", "Error Occurred While Deleting Categories");
    }
  };

  const goToEditPage = (id) => {
    navigate(`/dashboard/editcategories?id=${id}`);
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>All category</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <Link to="#" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Category</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <div className="text-tiny">All category</div>
                        </li>
                      </ul>
                    </div>
                    <div className="wg-box">
                      <div className="flex items-center justify-between gap10 flex-wrap">
                        <div className="wg-filter flex-grow">
                          
                        </div>
                        <Link className="tf-button style-1 w208"
                          to="/dashboard/newcategories" >
                          <FaPlus size={20} />
                          Add new
                        </Link>
                      </div>
                      <div className="wg-table table-all-category">
                        <ul className="table-title flex gap10 mb-14">
                          <li>
                            <div className="body-title"> Category Image </div>
                          </li>
                          <li>
                            <div className="body-title"> Category Name </div>
                          </li>
                          <li>
                            <div className="body-title"> Category ID </div>
                          </li>
                          <li>
                            <div className="body-title">Status</div>
                          </li>
                          <li>
                            <div className="body-title">Action</div>
                          </li>
                        </ul>
                        <ul className="flex flex-column">
                        {categories.map((category) => (
                          <li key={category} className="product-item gap14">
                            <div className="flex items-center justify-between gap20 flex-grow">
                              <div className="image">
                                {category.img ? <img src={category.img} style={{ width: '50px', height: '50px' }} alt={`${category.name}`} className="" /> : <div>Category Image</div>}
                              </div>
                              <div className="name">
                                <Link to="javascript:void(0)" className="body-title-2">
                                  {category.name}
                                </Link>
                              </div>
                              <div className="body-text">{category._id}</div>
                              <div className="block-available">{category.status}</div>
                              <div className="list-icon-function">
                                <div className="item edit" onClick={() => goToEditPage(category._id)} >
                                  <CiEdit color="green" />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                          {categories.length === 0 && (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={NoAdminImage} alt="No Category Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                              <h2>No Category Found </h2>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                      <div className="wg-pagination-wrap"></div>
                        <ul className="wg-pagination">
                          <li>
                            <Link to="#" onClick={handlePreviousPage} style={{ textDecoration: 'none' }}>
                              <FaChevronLeft />
                            </Link>
                          </li>
                          {[...Array(totalPages)].map((_, index) => (
                            <li key={index}>
                              <Link
                                to="#"
                                onClick={() => setCurrentPage(index + 1)}
                                style={{ textDecoration: 'none', fontWeight: currentPage === index + 1 ? 'bold' : 'normal' }}
                              >
                                {index + 1}
                              </Link>
                            </li>
                          ))}
                          <li>
                            <Link to="#" onClick={handleNextPage} style={{ textDecoration: 'none' }}>
                              <FaAngleRight />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                  Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesList;
