import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight } from "react-icons/fa6";
import { FiTruck } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; 
import LoadingGif from '../images/avatar/aino_loading.gif';

const OrderDetails = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const token = localStorage.getItem('token');
      try {
        setLoading(true);
        const response = await axios.get(
          `https://aino-tech-server.onrender.com/api/v1/orders/${orderId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setOrderDetails(response.data.order);
        setLoading(false);
      } catch (err) {
        setError("Error fetching order details");
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const convertExpirationTime = (createdAt) => {
    const date = new Date(createdAt);
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric', 
      hour12: true 
    };
    return date.toLocaleString('en-US', options);
  };

  function formatPrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleNavigate = (id) => {
    navigate(`/dashboard/ordertracking/${id}`);
  };

  // Conditional rendering for loading and error states
  if (loading) {
    return (
      <div className="preload-container">
        <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '150px' }}>
          <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
          <p>Loading...</p>
        </li>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div id="wrapper">
        <div id="page">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <div>
                        <h3>User Full Name: {orderDetails?.user?.firstName} {orderDetails?.user?.lastName}</h3>
                        <h3>User Email: {orderDetails?.user?.email}</h3>
                      </div>

                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li><FaAngleRight /></li>
                        <li>
                          <Link to="/dashboard/orderlist" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Order</div>
                          </Link>
                        </li>
                        <li><FaAngleRight /></li>
                        <li>
                          <Link to="#" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Order detail</div>
                          </Link>
                        </li>
                        <li><FaAngleRight /></li>
                        <li>
                          <div className="text-tiny">Order ID: {orderDetails._id}</div>
                        </li>
                      </ul>
                    </div>

                    {/* Order details */}
                    <div className="wg-order-detail">
                      <div className="left flex-grow">
                        <div className="wg-box mb-20">
                          <div className="wg-table table-order-detail">
                            <ul className="table-title flex items-center justify-between gap20 mb-24">
                              <li><div className="body-title">All items</div></li>
                            </ul>
                            <ul className="flex flex-column">
                              {orderDetails?.products?.map((item) => (
                                <li className="product-item gap14" key={item._id}>
                                  <div className="image no-bg">
                                    <img src={item.product?.images[0]} alt={item.product?.name} style={{ width: "100px" }} />
                                  </div>
                                  <div className="flex items-center justify-between gap40 flex-grow">
                                    <div className="name">
                                      <div className="text-tiny mb-1">Product name</div>
                                      <div className="body-title-2">{item.product?.name}</div>
                                    </div>
                                    <div className="name">
                                      <div className="text-tiny mb-1">Quantity</div>
                                      <div className="body-title-2">{item.quantity}</div>
                                    </div>
                                    <div className="name">
                                      <div className="text-tiny mb-1">Price</div>
                                      <div className="body-title-2"> &#8358; {formatPrice(item.priceAtPurchase)}</div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div className="wg-box">
                          <div className="wg-table table-cart-totals">
                            <ul className="table-title flex mb-24">
                              <li><div className="body-title">Cart Totals</div></li>
                              <li><div className="body-title">Price</div></li>
                            </ul>
                            <ul className="flex flex-column gap14">
                              <li className="cart-totals-item">
                                <span className="body-text">Subtotal:</span>
                                <span className="body-title-2">&#8358; {orderDetails?.subtotal}</span>
                              </li>
                              <li className="divider"></li>
                              <li className="cart-totals-item">
                                <span className="body-text">Shipping Fee:</span>
                                <span className="body-title-2">&#8358; {orderDetails?.shipping}</span>
                              </li>
                              <li className="divider"></li>
                              <li className="cart-totals-item">
                                <span className="body-title">Total price:</span>
                                <span className="body-title tf-color-2">&#8358; {formatPrice(orderDetails?.totalAmount)}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="right">
                        <div className="wg-box mb-20 gap10">
                          <div className="body-title">Summary</div>
                          <div className="summary-item">
                            <div className="body-text">Order ID</div>
                            <div className="body-title-2">{orderDetails?._id}</div>
                          </div>
                          <div className="summary-item">
                            <div className="body-text">Date Created</div>
                            <div className="body-title-2">{convertExpirationTime(orderDetails?.createdAt)}</div>
                          </div>
                          <div className="summary-item">
                            <div className="body-text">Total</div>
                            <div className="body-title-2 tf-color-2">&#8358; {formatPrice(orderDetails?.totalAmount)}</div>
                          </div>
                        </div>

                        <div className="wg-box mb-20 gap10">
                          <div className="body-title">Shipping Address</div>
                          <div className="body-text">{orderDetails?.shippingAddress}</div>
                        </div>

                        <div className="wg-box mb-20 gap10">
                          <div className="body-title">Payment Method</div>
                          <div className="body-text">{orderDetails?.paymentMethod}</div>
                        </div>

                        <div className="wg-box gap10">
                          <div className="body-title">Expected Date Of Delivery</div>
                          <div className="body-title-2 tf-color-2">{orderDetails?.deliveryDate}</div>
                          {/* <Link className="tf-button style-1 w-full" to="" onClick={() => handleNavigate(orderDetails._id)}>
                            <FiTruck size={20} />
                            Track order
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                    Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
