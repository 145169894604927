import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight, FaRegTrashCan, FaPlus, FaCheckDouble, FaRegCircleXmark } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import SweetAlertComponent from "../utils/alert";
import NoAdminImage from '../images/avatar/vendor.jpg'; 
import { useAuth } from "../component/AuthContext";  
import user5 from "../images/avatar/admin.png";

const Vendor = () => {
  const { vendor } = useAuth();
  const [vendorList, setVendorList] = useState(vendor); // Local state for vendors
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setVendorList(vendor); // Update local state whenever context changes
  }, [vendor]);

  const convertCreatedTime = (startDate) => {
    const date = new Date(startDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
  };

  const handleVerifyMerchant = async (merchantId) => {
    let sweetAlertClass = new SweetAlertComponent();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/verify-vendor/${merchantId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const result = await response.json();
      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "", `Verification Status: ${result.message}`);
        
        // Update the vendor list in the state
        setVendorList((prevList) => 
          prevList.map((vendor) => 
            vendor._id === merchantId ? { ...vendor, accountStatus: true } : vendor
          )
        );
      } else {
        sweetAlertClass.showSweetAlert(2, "", `Verification failed: ${result.message}`);
      }
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "", `There was an error verifying the merchant.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>All Merchants</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/dashboard" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <Link to="/dashboard/merchant" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Merchant</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <div className="text-tiny">Merchant List</div>
                        </li>
                      </ul>
                    </div>
                    <div className="wg-box">
                      <div className="wg-table table-all-user">
                        <ul className="table-title flex gap20 mb-14">
                          <li>
                            <div className="body-title">Merchant Name</div>
                          </li>
                          <li>
                            <div className="body-title">Merchant Email</div>
                          </li>
                          <li>
                            <div className="body-title">Account Status</div>
                          </li>
                          <li>
                            <div className="body-title">Account Verification</div>
                          </li>
                          <li>
                            <div className="body-title">Account Created</div>
                          </li>
                          <li>
                            <div className="body-title">Action</div>
                          </li>
                        </ul>
                        <ul className="flex flex-column">
                          {vendorList.map((vendors) => (
                            <li className="user-item gap14" key={vendors._id}>
                              <div className="image">
                                <img src={user5} alt="" className="" />
                              </div>
                              <div className="flex items-center justify-between gap20 flex-grow">
                                <div className="name">
                                  <Link to="#" className="body-title-2">
                                    {vendors.storeName}
                                  </Link>
                                </div>
                                <div className="body-text">{vendors.email}</div>
                                <div className="body-text">{vendors.accountStatus}</div>
                                {/* <div>
                                  <div className={vendors.accountStatus ? "block-available" : "block-not-available"}>
                                    {vendors.accountStatus ? "active" : "unverified"}
                                  </div>
                                </div> */}
                                <div>
                                  <div className={vendors.isVerified ? "block-available" : "block-not-available"}>
                                    {vendors.isVerified ? "True" : "False"}
                                  </div>
                                </div>
                                <div className="body-text">{convertCreatedTime(vendors.createdAt)}</div>
                                <div className="list-icon-function">
                                  
                                  {vendors.isVerified == false &&
                                    <div className="item edit">
                                      <FaCheckDouble color="green" onClick={() => handleVerifyMerchant(vendors._id)} />
                                    </div>

                                  }
                                  {/* <div className="item trash">
                                    <FaRegCircleXmark color="red" />
                                  </div> */}
                                </div>
                              </div>
                            </li>
                          ))}
                          {vendorList.length === 0 && (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={NoAdminImage} alt="No Product Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                              <h2>No Merchant found.</h2>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                        <div className="body-text"></div>
                        <ul className="wg-pagination">
                          <li>
                            <Link to="#" style={{ textDecoration: "none" }}>
                              <FaChevronLeft />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" style={{ textDecoration: "none" }}>
                              1
                            </Link>
                          </li>
                          <li>
                            <Link to="#" style={{ textDecoration: "none" }}>
                              <FaAngleRight />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                    Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
