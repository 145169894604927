import React from "react";
import Header from "../component/Header";
import SideBar from "../../component/Sidebar";
import NoAdminImage from '../../images/avatar/avatar.png';
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
const MerchantProductList = () => {
  return (
        <>
            <main id="main" class="main">

                <div className="pagetitle">
                <h1>List Of Product On Aino Platform</h1>
                    <nav>
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/merchant/merchantdashboard">Dashbaord</Link></li>
                        <li className="breadcrumb-item active">Product List</li>
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    <div className="row">
                        <div class="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                <div className=" d-flex justify-content-end mt-5">
                                    <Link to="" type="button" class="btn btn-outline-primary"> <MdOutlineAdd />Add Product</Link>
                                </div>
                                <h5 className="card-title"></h5>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Position</th>
                                                <th scope="col">Age</th>
                                                <th scope="col">Start Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>Brandon Jacob</td>
                                                <td>Designer</td>
                                                <td>28</td>
                                                <td>2016-05-25</td>
                                            </tr>
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    );
};

export default MerchantProductList;
