import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleRight } from "react-icons/fa6";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import SweetAlertComponent from "../utils/alert";
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../component/AuthContext";




const AddVendor = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        level: "",
        email: "",
        password: "",
        confirmPassword: "",
        image: "",
    });

    const [apiResponse, setApiResponse] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { role} = useAuth();
    const { setIsSaved } = useAuth();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData({ ...formData, image: reader.result });
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            sweetAlertClass.showSweetAlert(2, "", "Passwords do not match");
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await fetch("https://aino-tech-server.onrender.com/api/v1/admin/create-account", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    level: formData.level,
                    email: formData.email,
                    password: formData.password,
                    image: formData.image,
                }),
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            setIsSaved(true);
            sweetAlertClass.showSweetAlert(1, "", "New Staff Created");
            navigate('/dashboard/admin'); 
           
        } catch (error) {
            setError(error.message);
            console.error("There was an error!", error);
        }
    };

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>Create a New Merchant Account</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                <li>
                                                    <Link to="/" style={{ textDecoration: "none" }}>
                                                        <div className="text-tiny">Dashboard</div>
                                                    </Link>
                                                </li>
                                                
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <div className="text-tiny">Onboard a New Merchant</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <form className="form-add-new-user form-style-2" onSubmit={handleSubmit}>
                                            <div className="wg-box">
                                                <div className="left">
                                                    <h5 className="mb-4 fw-bolder">Account</h5>
                                                    <div className="body-text">
                                                        Fill in the information below to add a new Merchant Account
                                                    </div>
                                                </div>
                                                <div className="right flex-grow">
                                                    <fieldset className="name mb-24">
                                                        <div className="body-title mb-10">First Name</div>
                                                        <input className="flex-grow" type="text" placeholder="First Name" name="firstName" value={formData.firstName}
                                                            onChange={handleChange} aria-required="true" required />
                                                    </fieldset>
                                                    <fieldset className="name mb-24">
                                                        <div className="body-title mb-10">Last Name</div>
                                                        <input className="flex-grow" type="text" placeholder="Last Name" name="lastName" value={formData.lastName}
                                                            onChange={handleChange} aria-required="true" required />
                                                    </fieldset>
                                                    <fieldset className="name mb-24">
                                                        <div className="body-title mb-10">Level</div>
                                                        <select className="flex-grow" name="level" value={formData.level} onChange={handleChange} aria-required="true" required>
                                                            <option value="" disabled>Select Role</option>
                                                            {role.map(r => (
                                                                <option key={r._id} value={r._id}>{r.name}</option>
                                                            ))}
                                                        </select>
                                                    </fieldset>
                                                    <fieldset className="email mb-24">
                                                        <div className="body-title mb-10">Email</div>
                                                        <input className="flex-grow" type="email" placeholder="Email" name="email" value={formData.email}
                                                            onChange={handleChange} aria-required="true" required />
                                                    </fieldset>
                                                    <fieldset className="password mb-24">
                                                        <div className="body-title mb-10">Password</div>
                                                        <input className="password-input" type="password" placeholder="Enter password" name="password" value={formData.password}
                                                            onChange={handleChange} aria-required="true" required />
                                                        <span className="show-pass">
                                                            <FaRegEye className="view" />
                                                            <FaRegEyeSlash className="hide" />
                                                        </span>
                                                    </fieldset>
                                                    <fieldset className="password">
                                                        <div className="body-title mb-10"> Confirm password </div>
                                                        <input className="password-input" type="password" placeholder="Confirm password" name="confirmPassword" value={formData.confirmPassword}
                                                            onChange={handleChange} aria-required="true" required />
                                                        <span className="show-pass">
                                                            <FaRegEye className="view" />
                                                            <FaRegEyeSlash className="hide" />
                                                        </span>
                                                    </fieldset>
                                                    <fieldset className="image mt-24">
                                                        <div className="body-title mb-10">Profile Image</div>
                                                        <input type="file" accept="image/*" onChange={handleFileChange} aria-required="true" />
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div className="bot">
                                                <button className="tf-button w180" type="submit">
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">
                                        Copyright © 2024 Aino Tech Global. All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddVendor;
