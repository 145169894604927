import React, { createContext, useState, useContext, useEffect } from 'react';
import SweetAlertComponent from '../utils/alert';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categories, setCategories] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [user, setUser] = useState([]);
  const [influencer, setInfluencer] = useState([]);
  const [profile, setProfile] = useState({});
  const [flashSales, setFlashSales] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [vendorDashboard, setVendorDashboard] = useState([]);
  const [role, setRole] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [products, setProducts] = useState([]);
  const [pickupstation, setPickupStations] = useState([]);
  const [voucher, setVoucher] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [states, setStates] = useState([]);
  const [LGA, setLGA] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  


  const handleSubmit = async (e) => {
    let sweetAlertClass = new SweetAlertComponent();
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (!response.ok) {
        sweetAlertClass.showSweetAlert(2, "error", "Invalid Email or Password");
        throw new Error('Network response was not ok');
      }

      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "Success", "Login successful");
        localStorage.setItem('adminData', JSON.stringify(data));
        localStorage.setItem('token', data.token);
        setIsAuthenticated(true);
        // navigate('/dashboard'); // Redirect to the dashboard on successful login
      }
    } catch (error) {
      // Handle the error
      console.error('There was a problem with the login request:', error);
    }
  };

  // Other functions here...
  const categorieslist = async (page) => {
    const token = localStorage.getItem('token'); 
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/category/${page}/10', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCategories(data.categories);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const subCategoriesList = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/category/sub-category/1/10', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSubCategories(data.categories);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const adminList = async (page) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/admin/all-admins/1/10' , 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAdmin(data.data);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getProfile = async () => {
    const token = localStorage.getItem('token');
    try {
      const adminData = JSON.parse(localStorage.getItem('adminData'));
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/${adminData.id}` , 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setProfile(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getRole = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/admin/get-all-roles',
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRole(data.roles);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };


  const getVendor = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/admin/vendors/get-all-vendors/1/30', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setVendor(data.vendors);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const productsList = async (page) => {
    const token = localStorage.getItem('token');
    const productPerPage = 20;
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/product/${page}/${productPerPage}', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setProducts(data.products);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Banner 
  const getBannerList = async () => {
    const token = localStorage.getItem('token'); 
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/settings/get-sliders', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSliders(data.sliders);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  // state 
  const statesList = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/utilities/get-states', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setStates(data.data); 
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // LGA
  const LGAList = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/utilities/get-lga-of-state/:${state}', 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setLGA(data.local_govt);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };


  // Pick Up station
  const getAllPickupStations = async (page) => {
    const token = localStorage.getItem('token');
    const stationsPerPage = 20;
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/settings/get-all-location/${page}/${stationsPerPage}`, 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPickupStations(data.locations); 
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // voucher 
  const getAllVoucher = async (page) => {
    const voucherPerPage = 20;
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/vouchers/list?page=${page}&limit=${voucherPerPage}`, 
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setVoucher(data.vouchers); 
      setTotalPages(Math.ceil(data.vouchers.length / voucherPerPage));
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }
    // User 
    const getAllUser = async (page) => {
      const token = localStorage.getItem('token');
      const userPerPage = 20; 
      try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/users/get-all-users/${page}/${userPerPage}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        
        setUser(data.users);  // Set the users from the API response
        setTotalPages(Math.ceil(data.users.length / userPerPage)); // Set total pages
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    // influences
    const getAllInfluencer = async (page) => {
      const token = localStorage.getItem('token');
      const influencersPerPage = 20; 
      try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/influencers/list?page=${page}&limit=${influencersPerPage}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        
        setInfluencer(data.influencers);  // Set the users from the API response
        setTotalPages(Math.ceil(data.influencers.length / influencersPerPage)); // Set total pages
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
  
    
    // Get Flash Sales 
    const getAllFlashSales = async (page) => {
      const flashSalesPerPage = 20;
      const token = localStorage.getItem("token");
      setLoading(true);
      try {
        const response = await fetch(
          `https://aino-tech-server.onrender.com/api/v1/admin/inventory/get-all-flash-sales/${page}/${flashSalesPerPage}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch flash sales");
        }
  
        const data = await response.json();
        setFlashSales(data.flashSaleProducts);
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching flash sales:", error.message);
        setLoading(false);
      }
    };

    // Aino Dashbaord 
    const ainoDashBoard = async (page) => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/dashboard-stats` , 
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
              'Content-Type': 'application/json',
            },
          });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setDashboard(data.stats); 
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }; 

    // Vendor DashBoard
    const vendorDashBoard = async (page) => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/vendors/dashboard-data` , 
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
              'Content-Type': 'application/json',
            },
          });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVendorDashboard(data); 
        setTotalPages(data.totalPages);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }; 



  const logout = () => {
    localStorage.removeItem("adminData");
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = "/"; 
    console.log("This is Admin");// Adjust the URL path as per your app's routing structure
  };

  let timeoutId;

  const startLogoutTimer = () => {
    clearTimeout(timeoutId); // Clear the existing timer if any
    
    // Set a timeout for 10 minutes (10 * 60 * 1000 milliseconds)
    timeoutId = setTimeout(() => {
      
      logout(); // Call the logout function after 10 minutes of inactivity
      window.location.href = "/";
    }, 10 * 60 * 1000);
  };

  // Reset the inactivity timer on user activity
  const resetTimer = () => {
    startLogoutTimer(); // Start the logout timer
  };

  // Attach event listeners to detect user activity
  const setupActivityListeners = () => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);
  };

  // Call this function when the component/page is mounted
  const initializeInactivityLogout = () => {
    setupActivityListeners();
    startLogoutTimer(); // Start the logout timer when the app starts
  };

  useEffect(() => {
    categorieslist();
    adminList(currentPage);
    ainoDashBoard();
    productsList();
    getProfile();
    getRole();
    getBannerList();
    vendorDashBoard();
    getVendor();
    subCategoriesList();
    statesList();
    getAllPickupStations(currentPage);
    initializeInactivityLogout();
    // LGAList();
  }, [isSaved, currentPage ]);

  return (
    <AuthContext.Provider value={{
      handleSubmit,
      isAuthenticated,
      logout,
      categories,
      setIsSaved,
      dashboard,
      vendorDashboard,
      admin,
      user,
      products,
      profile,
      role,
      vendor,
      flashSales,
      getAllUser,
      getAllFlashSales,
      getAllVoucher,
      getAllInfluencer,
      subCategories,
      sliders,
      states,
      influencer,
      LGA,
      pickupstation,
      orderDetails,
      voucher,
      error,
      loading,
      totalPages
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
