import React from "react";
import '../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../images/logo/logo.png';
import { GoBell } from "react-icons/go";
import { LuMessageSquare } from "react-icons/lu";
import { FaRegSquare } from "react-icons/fa6";
import { LuLayoutGrid } from "react-icons/lu";
import { IoGridOutline } from "react-icons/io5";
import user5 from '../images/avatar/avatar2.png';
import { FaRegUserCircle } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { Link, NavLink} from "react-router-dom"
import { useAuth } from "./AuthContext";
import NoAdminImage from '../images/avatar/avatar2.png';

const Header = () => {
    const { logout } = useAuth();
    const { profile  } = useAuth();
    // console.log(profile);
    return ( 
        <div class="section-content-right">
           <div className="header-dashboard">
                <div className="wrap">
                    <div className="header-left">
                        <Link to="/dashboard">
                            <img src={logo} alt="" id="logo_header_mobile" data-width="154px" data-height="52px" />
                        </Link>
                        <div className="button-show-hide">
                            <MdOutlineKeyboardDoubleArrowLeft />
                        </div>
                    </div>
                    <div className="header-grid">
                        
                        <div className="popup-wrap user type-header">
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="header-user wg-user">
                                        <span className="image">
                                            <img src={profile?.image || NoAdminImage} alt={profile.firstName} className=""/>
                                        </span>
                                        <span className="flex flex-column">
                                            <span className="body-title">{profile?.firstName} {profile?.lastName}</span>
                                            <span className="text-tiny">{profile?.email}</span>
                                            <span className="text-tiny">{profile?.level?.name || "No Role"}</span>
                                        </span>
                                    </span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end has-content" aria-labelledby="dropdownMenuButton3" >
                                    <li>
                                        <a href="#" className="user-item" style={{ textDecoration: "none" }}>
                                            <div className="icon">
                                                <FaRegUserCircle size={20}/>
                                            </div>
                                            <div className="body-title-2" style={{textDecoration:"none"}}>Account</div>
                                        </a>
                                    </li>
                                    
                                    <li>
                                        <Link onClick={() => logout()} to="" className="user-item" style={{ textDecoration: "none" }}>
                                            <div className="icon">
                                                <CiLogout size={20}/>
                                            </div>
                                            <div className="body-title-2"style={{textDecoration:"none"}} >Log out</div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
    );
}
 
export default Header;