import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../component/Sidebar";
import Header from "../component/Header";
import { FaAngleRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { FaRegTrashCan } from "react-icons/fa6";
import { useAuth } from "../component/AuthContext";
import { Link } from "react-router-dom";

const AllRoles = () => {

  const convertCreatedTime = (startDate) => {
    const date = new Date(startDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
  };
  const { role} = useAuth();
  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>Role List</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Dashboard</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <Link to="#" style={{ textDecoration: "none" }}>
                            <div className="text-tiny">Roles</div>
                          </Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <div className="text-tiny">Role List</div>
                        </li>
                      </ul>
                    </div>
                    <div className="wg-box">
                      <div className="flex items-center justify-between gap10 flex-wrap">
                        <div className="wg-filter flex-grow">
                          
                        </div>
                        <Link className="tf-button style-1 w208"
                          to="/dashboard/createroles" >
                          <FaPlus size={20} />
                          Add role
                        </Link>
                      </div>
                      <div className="wg-table table-all-roles">
                        <ul className="table-title flex mb-14">
                          <li>
                            <div className="body-title">No</div>
                          </li>
                          <li>
                            <div className="body-title">Name</div>
                          </li>
                          <li>
                            <div className="body-title">Create at</div>
                          </li>
                          <li>
                            <div className="body-title">Action</div>
                          </li>
                        </ul>
                        <ul className="flex flex-column">
                        {role.map((role, index) => (
                          <li key={index} className="roles-item">
                            <div className="body-text">{(index + 1)}</div>
                            <div className="body-text">{role.name}</div>
                            <div className="body-text">{convertCreatedTime(role.createdAt)}</div>
                            <div className="list-icon-function">
                              <div className="item trash">
                                <FaRegTrashCan color="red" />
                              </div>
                            </div>
                          </li>
                        ))
                        }
                        {role.length === 0  && <li>No Role found.</li>}
                        </ul>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">
                    {" "}
                    Copyright © 2024 Aino Tech Global.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRoles;
