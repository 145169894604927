import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight, FaFileExcel } from "react-icons/fa6";
import { IoEyeOutline, IoSearchOutline } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import NoAdminImage from '../images/avatar/transaction.jpeg';
import LoadingGif from '../images/avatar/aino_loading.gif';
import { Modal, Button } from "react-bootstrap";
import { FaChevronLeft, FaSearch } from "react-icons/fa";
import SweetAlertComponent from "../utils/alert";
import * as XLSX from "xlsx";
import jsPDF from 'jspdf';

const TransactionList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [ searchTransaction, setSearchTransaction] = useState("");
  const [transaction, setTransaction] = useState([]);  // State to hold fetched orders
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [verify, setVerify] = useState([]);
  const [showTranscationModal, setShowTranscationModal] = useState(false); // Modal for product details
  const [selectedTranscation, setSelectedTranscation] = useState(null);
  const [showTranscationVerifyModal, setShowTranscationVerifyModal] = useState(false);
  const [selectedTranscationVerify, setSelectedTranscationVerify] = useState(null);
  const [loading, setLoading] = useState(true);  // Loading state
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [startDate, setStartDate] = useState(""); // YYYY/MM/DD format for startDate
  const [endDate, setEndDate] = useState("");
  // const { token } = useAuth();  // Assuming you have a token in AuthContext
  const navigate = useNavigate();
  const itemsPerPage = 20;
  
  // Fetch orders from the server
  const getAllTransaction = async (page = 1, limit = itemsPerPage, filter = '') => {
    setLoading(true); // Set loading state to true while fetching data
    const token = localStorage.getItem('token');
    let sweetAlertClass = new SweetAlertComponent();
    let url = `https://aino-tech-server.onrender.com/api/v1/payments/all/${page}/${limit}`;
    if (filter) {
      url += `?filter=${encodeURIComponent(filter)}`;
    }
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,  // Pass the token in the Authorization header
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch transaction details');
      }
      const data = await response.json();
      const totalPages = data.transactions.length < itemsPerPage ? page : page + 1; 
      setTransaction(data.transactions);  
      setTotalPages(totalPages); 
      setLoading(false); 
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "Error", error.message);
      // console.error('Error fetching transaction details:', error.message);
      setLoading(false);
    }
  };
  
  // Use useEffect to trigger transaction fetching when the component mounts or when the page changes
  useEffect(() => {
    getAllTransaction(currentPage); // Fetch transactions for the current page
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    if (!searchValue) {
      alert("Please enter a transaction ID.");
      return;
    }
    
    setLoading(true);
    const token = localStorage.getItem('token');
    const sweetAlertClass = new SweetAlertComponent();
    const url = `https://aino-tech-server.onrender.com/api/v1/payments/transaction/${searchValue}`;
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch transaction details');
      }

      const data = await response.json();
      setSearchTransaction(data.transaction); // Set the fetched transaction details
      setLoading(false);
    } catch (error) {
      sweetAlertClass.showSweetAlert(2, "Error", error.message);
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      getAllTransaction(newPage, itemsPerPage);  // Fetch the transactions for the new page
    }
  };

  const handleShowPickUpModal = (item) => {
    setSelectedTranscation(item);
    setShowTranscationModal(true);
  };  

  const handleClosePickUpModal = () => {
      setShowTranscationModal(false);
      setSelectedTranscation(null);
  };

  const handleShowVerifyModal = (item) => {
    setSelectedTranscationVerify(item);
    setShowTranscationVerifyModal(true);
  };
  
  const handleCloseVerifyModal = () => {
    setShowTranscationVerifyModal(false);
    setSelectedTranscationVerify(null);
  }; 

  const handleExportToExcel = (selectedTransactionId) => {
    const selectedTransaction = transaction.find(item => item._id === selectedTransactionId);
  
    if (!selectedTransaction) {
      alert("Transaction not found.");
      return;
    }
  
    const dataToExport = [{
      CustomerName: selectedTransaction.customer?.name || "",
      CustomerEmail: selectedTransaction.customer?.email || "",
      TransactionID: selectedTransaction._id,
      Reference: selectedTransaction.tx_ref,
      Status: selectedTransaction.status,
      Currency: selectedTransaction.currency,
      ChannelReference: selectedTransaction.reference,
      Channel: selectedTransaction.channel,
      CreatedAt: convertExpirationTime(selectedTransaction.createdAt),
      Amount: selectedTransaction.amount,
      TransactionChannelFee: selectedTransaction.fee,
      SettledAmount: selectedTransaction.settled_amount
    }];
  
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transaction");
  
    // Export the excel file
    XLSX.writeFile(workbook, `Transaction_${selectedTransactionId}.xlsx`);
  };
  
  const handleExportToPdf = (transactionId) => {
    // Assuming you have a way to get the transaction by ID
    const transaction = transaction.find(t => t._id === transactionId);
  
    if (!transaction) return;
  
    const doc = new jsPDF();
  
    // Add title to PDF
    doc.setFontSize(20);
    doc.text("Transaction Details", 10, 10);
  
    // Add transaction details to PDF
    doc.setFontSize(12);
    doc.text(`Transaction ID: ${transaction._id}`, 10, 20);
    doc.text(`Customer Name: ${transaction.customer?.name || ''}`, 10, 30);
    doc.text(`Customer Email: ${transaction.customer?.email || ''}`, 10, 40);
    doc.text(`Transaction Reference: ${transaction.tx_ref}`, 10, 50);
    doc.text(`Transaction Status: ${transaction.status}`, 10, 60);
    doc.text(`Created At: ${convertExpirationTime(transaction.createdAt)}`, 10, 70);
  
    // Save the PDF
    doc.save(`transaction_${transactionId}.pdf`);
  };

  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  const handleExportClick = () => {
    if (!startDate || !endDate) {
      alert('Please select both start and end dates.');
      return;
    }
    handleExportToExcelDate(startDate, endDate);
  };

  const handleExportToExcelDate = (startDate, endDate) => {
    // Ensure startDate and endDate are provided
    if (!startDate || !endDate) {
      alert("Please select a start date and an end date.");
      return;
    }
  
    // Format export URL with page, limit, startDate, and endDate
    const exportUrl = `https://aino-tech-server.onrender.com/api/v1/payments/all/${currentPage}/${itemsPerPage}?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
  
    // Fetch and export data from the server
    fetch(exportUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Process and export the data to Excel
        const dataToExport = data.transactions.map((transaction, index) => ({
          Number: index + 1,
          CustomerName: transaction.customer?.name || "",
          CustomerEmail: transaction.customer?.email || "",
          TransactionID: transaction._id,
          Reference: transaction.tx_ref,
          Status: transaction.status,
          Currency: transaction.currency,
          ChannelReference: transaction.reference,
          Channel: transaction.channel,
          CreatedAt: convertExpirationTime(transaction.createdAt),
          Amount: transaction.amount,
          TransactionChannelFee: transaction.fee,
          SettledAmount: transaction.settled_amount,
        }));
  
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Transaction");
  
        // Export the Excel file
        XLSX.writeFile(workbook, `Transaction_${startDate}_to_${endDate}.xlsx`);
      })
      .catch((error) => {
        console.error('Error exporting transactions:', error);
      });
  };
  


  function formatPrice(price) {
    if (price === undefined || price === null) {
      return "0"; // Return a default value if price is undefined or null
    }
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const convertExpirationTime = (startDate) => {
    const date = new Date(startDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return date.toLocaleString('en-US', options);
  };

  const handleVerify = async (reference) => {
    let sweetAlertClass = new SweetAlertComponent();
    const token = localStorage.getItem('token');
    
    try {
      const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/payments/verify-payment/${reference}`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
          }
      });

      if (!response.ok) {
          throw new Error('Failed to verify transaction');
      }

      const data = await response.json();
      
      if (data.success && typeof data.response === 'string') {
        sweetAlertClass.showSweetAlert(2, "error", data.response);  // Show API response message
        return;
      }

      if (data.response.requestSuccessful) {
        setVerify(data.response.responseBody); 
        setShowTranscationVerifyModal(true);  
        sweetAlertClass.showSweetAlert(1, "success", "Transaction Verified Successfully");
      } else {
        sweetAlertClass.showSweetAlert(1, "success", data.response); 
      }

    } catch (error) {
        sweetAlertClass.showSweetAlert(2, "error", "Error Occurred While Verifying Transaction: " + error.message);
    }
  };


  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3>All Transactional List</h3>
                    </div>
                    <div className="wg-box">
                      <div className="flex items-center justify-between gap10 flex-wrap">
                          <div className="wg-filter flex-grow">
                          <form className="form-search" onSubmit={handleSearchSubmit} >
                            <fieldset className="name">
                              <input className="mb-10" type="text" placeholder="Search By Transaction Id" name=""  value={searchValue} onChange={handleSearchChange} />
                            </fieldset>
                            <div className="button-submit">
                              <button className="" type="submit"  >
                                <IoSearchOutline />
                              </button>
                            </div>
                          </form>
                          </div>
                          <Link className="tf-button style-1 w208" onClick={handleOpenModal} to=""><FaFileExcel />Export Transaction</Link>
                      </div>
                      <div className="wg-table table-all-category">
                        <ul className="table-title flex gap20 mb-14">
                          <li><div className="body-title">Customers Info </div></li>
                          <li><div className="body-title">Trans Id</div></li>
                          <li><div className="body-title">Trans Ref</div></li>
                          <li><div className="body-title">Trans Status</div></li>
                          <li><div className="body-title">Trans Date</div></li>
                          <li><div className="body-title">Action</div></li>
                        </ul>
                        <ul className="flex flex-column">
                          {loading ? (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={LoadingGif} alt="Loading" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                              <p>Loading List of Transactional Data...</p>
                            </li>
                          ) : ( 
                            transaction?.map((item, index) => (
                              <li key={index} className="product-item gap14">
                                <div className="body-text">{index + 1} </div>
                                <div className="flex items-center justify-between gap20 flex-grow">
                                  <div className="name">
                                    <Link to="#" className="body-title-2">
                                      {item.customer?.name || ""}
                                    </Link>
                                    <div className="text-tiny mt-4">
                                      {item.customer?.email || ""}
                                    </div>
                                  </div>
                                  <div className="body-text">{item._id} </div>
                                  <div className="body-text">{item.tx_ref} </div>
                                  <div>
                                    <div className={item.status === 'failed' ? 'block-not-available' : item.status === 'success' ? 'block-available'  : item.status === 'initiated' ? 'block-pending' : '' }>
                                      {item.status}
                                    </div>
                                  </div>
                                  <div className="body-text">{convertExpirationTime(item.createdAt)} </div>
                                  
                                  <div className="list-icon-function">
                                    <div className="item eye" onClick={() => handleShowPickUpModal(item)}>
                                      <IoEyeOutline color="blue" />
                                    </div>
                                    <div className="dropdown default">
                                      <button
                                        className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        <span className="item edit">
                                          <CiEdit color="green" />
                                        </span>
                                      </button>
                                      <ul className="dropdown-menu dropdown-menu-end" style={{ backgroundColor: "white" }}>
                                        <li>
                                          <Link to="#" onClick={() => handleVerify(item.reference)} style={{ textDecoration: "none", color: "black" }}> Verify Transaction </Link>
                                        </li>
                                        <li className="divider"></li>
                                        <li>
                                          <Link to="#" onClick={() => handleExportToExcel(item._id)} style={{ textDecoration: "none", color: "black" }}> Export To Excel </Link>
                                        </li>
                                        <li className="divider"></li>
                                        <li>
                                          <Link to="#" onClick={() => handleExportToPdf(item._id)} style={{ textDecoration: "none", color: "black" }}> Export To Pdf </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))
                          )}
                          {transaction?.length === 0 && !loading && (
                            <li style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                              <img src={NoAdminImage} alt="No Order Found" style={{ width: '300px', height: '300px', borderRadius: '50%', objectFit: 'cover' }} />
                              <h2>No Transactions Found</h2>
                            </li>
                          )}
                        </ul>

                      </div>
                      <div className="divider"></div>
                      <div className="flex items-center justify-between flex-wrap gap10">
                        <div className="wg-pagination-wrap"></div>
                          <ul className="wg-pagination">
                            <li>
                              <Link to="#" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} > <FaChevronLeft /></Link>
                            </li>
                            <li>{currentPage}</li>
                            {currentPage < totalPages && (
                              <li>
                                <Link to="#" onClick={() => handlePageChange(currentPage + 1)}> <FaAngleRight /></Link>
                              </li>
                            )}
                          </ul>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Show More Model */}
      <Modal show={showTranscationModal} onHide={handleClosePickUpModal}>
        <Modal.Header closeButton>
        <Modal.Title>Transaction Full Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {selectedTranscation && (
          <div>
            <p>Customers Name: <h4> {selectedTranscation.customer?.name} </h4></p>
            <p>Customers Email: <h4> {selectedTranscation.customer?.email}</h4></p>
            <p>Transaction Status: <h4 className={selectedTranscation.status === 'failed' ? 'block-not-available' : selectedTranscation.status === 'success' ? 'block-available'  : selectedTranscation.status === 'initiated' ? 'block-pending' : '' } >{selectedTranscation.status}</h4> </p>
            <p>Transaction Reference: <h4>{selectedTranscation.tx_ref}</h4></p>
            <p>Transaction ID: <h4>{selectedTranscation._id}</h4></p>
            <p>Channel Reference: <h4>{selectedTranscation.reference}</h4></p>
            <p>Transaction Channel: <h4>{selectedTranscation.channel || "Unknown Channel"}</h4></p>
            <p>Transaction Currency Used: <h4>{selectedTranscation.currency || "Unknown Currency"}</h4></p>
            <p>Transaction Amount: <h4> &#8358; {formatPrice(selectedTranscation.amount)} </h4></p>
            <p>Transaction Channel Fee: <h4> &#8358; {formatPrice(selectedTranscation.fee)} </h4></p>
            <p>Transaction Total Amount Paid: <h4> &#8358; {formatPrice(selectedTranscation.settled_amount)} </h4></p>
            <p>Transaction Date: <h4> {convertExpirationTime(selectedTranscation.createdAt)} </h4></p>
          </div>
        )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePickUpModal}>Close</Button>

        </Modal.Footer>
      </Modal>

      {/* Verify Model */}
      <Modal show={showTranscationVerifyModal} onHide={handleCloseVerifyModal}>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Verification Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {verify && (
            <div>
              <p>Customer Name: <h4>{verify.customer?.name || ""}</h4></p>
              <p>Customer Email: <h4>{verify.customer?.email || ""}</h4></p>
              <p>Customer Phone Number: <h4>{verify.customer?.phone_number || "NULL"}</h4></p>
              <p>Transaction Status: <h4 className="block-available">{verify.status}</h4></p>
              <p>Transaction Reference: <h4>{verify.tx_reference}</h4></p>
              <p>ERC Reference: <h4>{verify.ercs_reference}</h4></p>
              <p>Transaction Amount: <h4>&#8358; {formatPrice(verify.amount)}</h4></p>
              <p>Transaction Fee: <h4>&#8358; {formatPrice(verify.fee)}</h4></p>
              <p>Settled Amount: <h4>&#8358; {formatPrice(verify.settled_amount)}</h4></p>
              <p>Transaction Channel: <h4>{verify.channel || ""}</h4></p>
              <p>Transaction Currency: <h4>{verify.currency || ""}</h4></p>
              <p>Transaction Date: <h4>{convertExpirationTime(verify.created_at)}</h4></p>
              <p>Paid At: <h4>{convertExpirationTime(verify.paid_at)}</h4></p>
              <p>Description: <h4>{verify.description}</h4></p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVerifyModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Export to Excel Model */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Export Transaction To Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body className="wg-box">
          <form>
            <fieldset className="name">
              <div className="body-title">Select Transaction Date From <span className="tf-color-1">*</span></div>
              <input type="date" placeholder="Select Transaction Start from" className="" name="startDate" value={startDate} onChange={handleStartDateChange} tabIndex="2" aria-required="true" required="" />
            </fieldset>
            <fieldset className="name">
              <div className="body-title">Select Transaction Date To <span className="tf-color-1">*</span></div>
              <input type="date" placeholder="Select Transaction Start to" className="" name="startDate" value={endDate} onChange={handleEndDateChange} tabIndex="2"  aria-required="true" required="" />
            </fieldset>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleExportClick}>Export To Excel</Button>
          <Button variant="secondary" onClick={handleCloseModal}>Cancel Export</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default TransactionList;
