import React, { useState, useEffect } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { useAuth } from "../component/AuthContext";
import { useNavigate, Link, useParams } from 'react-router-dom';
import SweetAlertComponent from "../utils/alert";

const EditPickUpList = () => {
    const { states } = useAuth();
    const [LGAs, setLGAs] = useState([]);
    const [formData, setFormData] = useState({
        address: "",
        description: "",
        state: "",
        LGA: "",
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setIsSaved } = useAuth();
    const [error, setError] = useState(null);
    const { locationId } = useParams(); // Get the ID from URL params

    // Fetch the pickup station details by ID on component mount
    useEffect(() => {
        const fetchPickupStationDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/pickupstations/${locationId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch pickup station details');
                }

                const data = await response.json();
                // Pre-fill the form with fetched data
                setFormData({
                    state: data.state,
                    LGA: data.lga,
                    address: data.address,
                    description: data.description,
                });
            } catch (error) {
                setError(error.message);
            }
        };

        fetchPickupStationDetails();
    }, [locationId]);

    useEffect(() => {
        if (formData.state) {
            fetchLGAs(formData.state);
        }
    }, [formData.state]);

    const fetchLGAs = async (selectedState) => {
        try {
            const token = localStorage.getItem('token');
            setLoading(true);
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/utilities/get-lga-of-state/${selectedState}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch LGAs');
            }

            const data = await response.json();
            setFormData({ ...formData, LGA: "" }); // Reset LGA selection
            setLGAs(data.local_govt);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://aino-tech-server.onrender.com/settings/edit-pickup-location/${locationId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    state: formData.state,
                    lga: formData.LGA,
                    description: formData.description,
                    address: formData.address,
                }),
            });

            if (!response.ok) {
                sweetAlertClass.showSweetAlert(2, "Error", error.message || "Failed to update pickup station.");
                setError(error.message);
                // throw new Error("Failed to update pickup station");
            }

            const result = await response.json();
            setIsSaved(true);
            sweetAlertClass.showSweetAlert(1, "", "Pick Up Station Updated");
            navigate('/dashboard/pickupstationlist');
        } catch (error) {
            sweetAlertClass.showSweetAlert(2, "Error", error.message || "An error occurred while updating the pickup station.");
            setError(error.message);
        }
    };

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>Edit an Existing Pick Up Station</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                <li>
                                                    <Link to="/" style={{ textDecoration: "none" }}><div className="text-tiny">Dashboard</div></Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <Link to="#" style={{ textDecoration: "none" }}><div className="text-tiny">Pick Up Station</div></Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <div className="text-tiny">Edit Pick Up Station</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="wg-box">
                                            <form className="form-new-product form-style-1" onSubmit={handleSubmit}>
                                                <fieldset className="name mb-24">
                                                    <div className="body-title mb-10"> Pick Up State</div>
                                                    <select className="flex-grow" name="state" value={formData.state} onChange={handleChange} aria-required="true" required>
                                                        <option value="" disabled>Select State</option>
                                                        {states.map(state => (
                                                            <option key={state} value={state}>{state}</option>
                                                        ))}
                                                    </select>
                                                </fieldset>
                                                <fieldset className="name mb-24">
                                                    <div className="body-title mb-10">Local Government Area of the Pick Up Station</div>
                                                    <select className="flex-grow" name="LGA" value={formData.LGA} onChange={handleChange} aria-required="true" required disabled={!formData.state || loading}>
                                                        <option value="" disabled={!formData.state}>Select LGA</option>
                                                        {LGAs.map(lga => (
                                                            <option key={lga} value={lga}>{lga}</option>
                                                        ))}
                                                    </select>
                                                </fieldset>
                                                <fieldset className="name">
                                                    <div className="body-title">Pick Up address<span className="tf-color-1">*</span></div>
                                                    <input type="text" placeholder="Address Name" className="" name="address" tabIndex="2" onChange={(e) => setFormData({ ...formData, address: e.target.value })} value={formData.address} aria-required="true" required />
                                                </fieldset>
                                                <fieldset className="name">
                                                    <div className="body-title">Pick Up Description<span className="tf-color-1">*</span></div>
                                                    <input type="text" placeholder="Pick Up Description" className="" name="description" tabIndex="2" onChange={(e) => setFormData({ ...formData, description: e.target.value })} value={formData.description} aria-required="true" required />
                                                </fieldset>

                                                <div className="bot">
                                                    <button className="tf-button w208" type="submit" disabled={!formData.state || !formData.LGA || loading}>Update Location</button>
                                                    <button onClick={() => navigate('/dashboard/pickupstationlist')} className="tf-button w208" type="button">Cancel Update</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPickUpList;
