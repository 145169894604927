import React from "react";
import logo from "../../images/logo/logo.png";
import NoAdminImage from '../../images/avatar/avatar.png';
import { useAuth } from "../../merchant/component/AuthContext";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
    const { merchantlogout } = useAuth();
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
            <Link to="#" className="logo d-flex align-items-center">
                <img src={logo} alt="Logo" />
            </Link>
            <i className="bi bi-list toggle-sidebar-btn"></i>
        </div>

        <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
                <li className="nav-item dropdown">

                </li>

                <li className="nav-item dropdown">

                </li>

                <li className="nav-item dropdown pe-3">
                    <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="" data-bs-toggle="dropdown">
                        <img src={NoAdminImage} alt="Profile" className="rounded-circle"/>
                    <span className="d-none d-md-block dropdown-toggle ps-2">K. Anderson</span>
                    </Link>

                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li className="dropdown-header">
                            <h6>Kevin Anderson</h6>
                            <span>Web Designer</span>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        <li>
                            <Link className="dropdown-item d-flex align-items-center" to="/merchant/merchantdetails">
                                <i className="bi bi-person"></i>
                            <span>Merchant Profile</span>
                            </Link>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        <li>
                            <Link onClick={() => merchantlogout()} className="dropdown-item d-flex align-items-center" to="">
                            <i className="bi bi-box-arrow-right"></i>
                            <span>Log Out</span>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>

    </header>
  );
};

export default Header;
