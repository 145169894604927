import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../component/Sidebar";
import Header from "../component/Header";
import { FaAngleRight } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";


const NewSubCategories = () => {
    const navigate = useNavigate();
    const { categories } = useAuth();
    const { setIsSaved } = useAuth();
    const [name, setName] = useState("");
    const [parentId, setParentId] = useState("");
    const [image, setImage] = useState(null); // State for storing the image file
    const [message, setMessage] = useState('');

    const NewCatergories = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
    
        try {
            let imageUrl = null;
            if (image) {
                imageUrl = await uploadImage(image); // Upload the image and get its URL
            }
            const newCategory = {
                name,
                parentId,
                img: imageUrl, // Send the image URL along with the name
            };
            // Save the new category to the backend
            const token = localStorage.getItem('token');
            const response = await fetch('https://aino-tech-server.onrender.com/api/v1/category/sub-category', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(newCategory),
            });
            const data = await response.json();
            if (response.ok) {
                setIsSaved(true);
                sweetAlertClass.showSweetAlert(1, "", "New Sub Categories Created");
                navigate('/dashboard/subcategories');
                setName('');
                setParentId('');
            } else {
                sweetAlertClass.showSweetAlert(2, "", "Error Creating New Sub Categories");
            }
        } catch (error) {
          setMessage(`Error: ${error.message}`);
        }
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
    
        try {
            const response = await fetch('https://ainotechglobal.com/upload.php', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            // console.log('Image uploaded successfully:', data);
            if (response.ok) {
                return data.url;
                // console.log('Image uploaded successfully:', data.url);
            } else {
                throw new Error('Failed to upload image');
                
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file); // Set the image file in state
    };


    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>Sub Category infomation</h3>
                                            <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                                                <li>
                                                    <Link to="/" style={{ textDecoration: "none" }}><div className="text-tiny">Dashboard</div></Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <Link to="#" style={{ textDecoration: "none" }}><div className="text-tiny">Sub Category</div></Link>
                                                </li>
                                                <li>
                                                    <FaAngleRight />
                                                </li>
                                                <li>
                                                    <div className="text-tiny">Create New Sub Category</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="wg-box">
                                            <form className="form-new-product form-style-1" onSubmit={NewCatergories}>
                                                <fieldset className="category">
                                                    <div className="body-title mb-10"> Category Name <span className="tf-color-1">*</span> </div>
                                                    <div className="select">
                                                        <select className="" onChange={(e) => setParentId(e.target.value)} value={parentId} >
                                                            <option>Choose category</option>
                                                            {categories.map((category) => (
                                                                <option key={category._id} value={category._id}>
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="name">
                                                    <div className="body-title">Sub Category name <span className="tf-color-1">*</span></div>
                                                    <input type="text" placeholder="Sub Catergory Name" className="" name="name" tabIndex="2" onChange={(e) => setName(e.target.value)} value={name} aria-required="true" required="" />
                                                </fieldset>
                                                <fieldset className="image mt-24">
                                                    <div className="body-title mb-10">Sub Category Image</div>
                                                    <input type="file" accept="image/*" onChange={handleFileChange} aria-required="true" />
                                                </fieldset>
                                                <div className="bot">
                                                    <div></div>
                                                    <button className="tf-button w208" type="submit">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewSubCategories;
