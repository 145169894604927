import React from "react";
import { Link, Navigate } from 'react-router-dom';
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import user5 from "../images/avatar/admin.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FiChevronRight, FiChevronLeft, FiDollarSign } from "react-icons/fi";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { CiFileOn } from "react-icons/ci";
import { LuUsers } from "react-icons/lu";
import { useAuth } from "../component/AuthContext";
import { useState } from "react";

const VendorIndex = () => {
    const { vendorDashboard = {} } = useAuth();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    function formatPrice(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <div id="wrapper">
            <div id="page" className="">
                <div className="layout-wrap">
                    <div className="section-content-right">
                        <div className="main-content">
                            <div className="main-content-inner">
                                <div className="main-content-wrap">
                                    <div className="tf-section-4 mb-30">
                                        <div className="wg-chart-default">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap14">
                                                    <div class="hexagon">
                                                        <HiOutlineShoppingBag color="white" size={10} />
                                                    </div>
                                                    <div>
                                                        <div className="body-text mb-2">Today Sales</div>
                                                        <h4>{formatPrice(vendorDashboard.todaySales?.totalSales || 0)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wg-chart-default">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap14">
                                                    <div class="hexagon-2">
                                                        <FiDollarSign color="white" size={10} />
                                                    </div>
                                                    <div>
                                                        <div className="body-text mb-2">Today Sales In Order</div>
                                                        <h4>{formatPrice(vendorDashboard.todaySales?.totalOrders || 0)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wg-chart-default">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap14">
                                                    <div class="hexagon-3">
                                                        <CiFileOn color="white" size={10} />
                                                    </div>
                                                    <div>
                                                        <div className="body-text mb-2">Today Order in Items</div>
                                                        <h4>{formatPrice(vendorDashboard.todayOrders?.totalItems || 0)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wg-chart-default">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap14">
                                                    <div class="hexagon-4">
                                                        <LuUsers color="white" size={10} />
                                                    </div>
                                                    <div>
                                                        <div className="body-text mb-2">Today Order in Amount</div>
                                                        <h4> &#x20A6;{formatPrice(vendorDashboard.todayOrders?.totalAmount || 0)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-section-4 mb-30">
                                        <div className="wg-chart-default">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap14">
                                                    <div className="hexagon">
                                                        <HiOutlineShoppingBag color="white" size={10} />
                                                    </div>
                                                    <div>
                                                        <div className="body-text mb-2">Week Sales</div>
                                                        <h4>{formatPrice(vendorDashboard.weekSales?.totalSales || 0)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wg-chart-default">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap14">
                                                    <div className="hexagon-2">
                                                        <FiDollarSign color="white" size={10} />
                                                    </div>
                                                    <div>
                                                        <div className="body-text mb-2">Week Orders</div>
                                                        <h4>{formatPrice(vendorDashboard.weekOrders?.totalOrders || 0)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wg-chart-default">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap14">
                                                    <div className="hexagon">
                                                        <HiOutlineShoppingBag color="white" size={10} />
                                                    </div>
                                                    <div>
                                                        <div className="body-text mb-2">Month Sales</div>
                                                        <h4>{formatPrice(vendorDashboard.monthSales?.totalSales || 0)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wg-chart-default">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap14">
                                                    <div className="hexagon-2">
                                                        <FiDollarSign color="white" size={10} />
                                                    </div>
                                                    <div>
                                                        <div className="body-text mb-2">Month Orders</div>
                                                        <h4>{formatPrice(vendorDashboard.monthOrders?.totalOrders || 0)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-section-6 mb-30">
                                        <div className="wg-box">
                                            <div className="flex items-center justify-between">
                                                <h5>Top Products</h5>
                                            </div>
                                            <div className="wg-table table-top-product">
                                                <ul className="flex flex-column gap14">
                                                    <li className="product-item">
                                                        <div className="image">
                                                            <img src={user5} alt="" className="" />
                                                        </div>
                                                        <div className="flex items-center justify-between flex-grow">
                                                            <div className="name">
                                                                <Link to="" className="body-title-2 mb-3" >
                                                                    Pedigree Adult Dry Dog...
                                                                </Link>
                                                                <div className="text-tiny">100 Items</div>
                                                            </div>
                                                            <div>
                                                                <div className="text-tiny mb-3">
                                                                    Coupon Code
                                                                </div>
                                                                <div className="body-text">Sflat</div>
                                                            </div>
                                                            <div className="country">
                                                                <img src={user5} alt="" className="" />
                                                            </div>
                                                            <div>
                                                                <div className="body-title-2 mb-3">-15%</div>
                                                                <div className="text-tiny">$27.00</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="flex items-center justify-between flex-wrap gap10">
                                                <div className="text-tiny">Showing 10 Latest entries</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tf-section-6 mb-30">
                                        <div className="wg-box">
                                            <div className="flex items-center justify-between">
                                                <h5>Recent Product</h5>
                                            </div>
                                            <div className="wg-table table-product-overview">
                                                <ul className="table-title flex gap20 mb-14">
                                                    <li>
                                                        <div className="body-title">Name</div>
                                                    </li>
                                                    <li>
                                                        <div className="body-title">Product ID</div>
                                                    </li>
                                                    <li>
                                                        <div className="body-title">Price</div>
                                                    </li>
                                                    <li>
                                                        <div className="body-title">Quantity</div>
                                                    </li>
                                                    <li>
                                                        <div className="body-title">Sale</div>
                                                    </li>
                                                    <li>
                                                        <div className="body-title">Revenue</div>
                                                    </li>
                                                    <li>
                                                        <div className="body-title">Status</div>
                                                    </li>
                                                </ul>
                                                <ul className="flex flex-column gap10">
                                                    <li className="product-item gap14">
                                                        <div className="image">
                                                            <img src={user5} alt="" className="" />
                                                        </div>
                                                        <div className="flex items-center justify-between flex-grow">
                                                            <div className="name">
                                                                <Link to="" className="body-text">
                                                                    Soft Fluffy Cats
                                                                </Link>
                                                            </div>
                                                            <div className="body-text">#327</div>
                                                            <div className="body-text">$11.70</div>
                                                            <div className="body-text">28</div>
                                                            <div className="body-text">On sale</div>
                                                            <div className="body-text">$328.85</div>
                                                            <div className="block-not-available">
                                                                Not Available
                                                            </div>
                                                        </div>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="flex items-center justify-between flex-wrap gap10">
                                                <div className="text-tiny">Showing 10 Latest entries</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-page">
                                <div className="body-text">
                                    Copyright © 2024 Aino Tech Global.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendorIndex;
