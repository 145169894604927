import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import { FaAngleRight } from "react-icons/fa6";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import SweetAlertComponent from "../utils/alert";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../component/AuthContext";

const AddAdvert = () => {
    const [formData, setFormData] = useState({
        name: "",
        image: "",
    });

    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setIsSaved } = useAuth();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files); 
        const uploadedImages = [];
    
        for (const file of files) {
            try {
                const imageUrl = await uploadImage(file);
                uploadedImages.push(imageUrl); 
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    
        setFormData((prevFormData) => ({
            ...prevFormData,
            image: uploadedImages[0] || "",  // Store the first uploaded image URL
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let sweetAlertClass = new SweetAlertComponent();
        
        // Validate that an image has been uploaded
        if (!formData.image) {
            setError("Please upload an image.");
            return;
        }
        const token = localStorage.getItem('token');
        try {
            const response = await fetch("https://aino-tech-server.onrender.com/api/v1/settings/add-slider", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    title: formData.name,
                    imagePath: formData.image,  // Use the stored image URL
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            setIsSaved(true);
            sweetAlertClass.showSweetAlert(1, "", "New Aino Advert Created");
            navigate('/dashboard/advert'); 
           
        } catch (error) {
            setError(error.message);
            console.error("There was an error!", error);
        }
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        
        try {
            const response = await fetch('https://ainotechglobal.com/upload.php', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (response.ok) {
                return data.url;  // Return the image URL
            } else {
                throw new Error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    return (
        <div>
            <div id="wrapper">
                <div id="page" className="">
                    <div className="layout-wrap">
                        <div className="section-content-right">
                            <div className="main-content">
                                <div className="main-content-inner">
                                    <div className="main-content-wrap">
                                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                                            <h3>Create A New Banner</h3>
                                        </div>
                                        {error && <div className="alert alert-danger">{error}</div>} 
                                        <form className="form-add-new-user form-style-2" onSubmit={handleSubmit}>
                                            <div className="wg-box">
                                                <div className="right flex-grow">
                                                    <fieldset className="name mb-24">
                                                        <div className="body-title mb-10">Banner Title</div>
                                                        <input className="flex-grow" type="text" placeholder="Banner Name" name="name" value={formData.name} onChange={handleChange} aria-required="true" required />
                                                    </fieldset>
                                                    
                                                    <fieldset className="image mt-24">
                                                        <div className="body-title mb-10">Banner Image</div>
                                                        <input type="file" accept="image/*" onChange={handleFileChange} aria-required="true" />
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div className="bot">
                                                <button className="tf-button w180" type="submit">
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="bottom-page">
                                    <div className="body-text">
                                        Copyright © 2024 Aino Tech Global. All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAdvert;
