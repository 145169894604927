import React, { createContext, useState, useContext, useEffect } from 'react';
import SweetAlertComponent from '../../utils/alert';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let sweetAlertClass = new SweetAlertComponent();
    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      setLoading(true);
      const response = await fetch('https://aino-tech-server.onrender.com/api/v1/vendor/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      
      if (response.ok) {
        sweetAlertClass.showSweetAlert(1, "Success", "Login successful");
        localStorage.setItem('merchantData', JSON.stringify(data));
        localStorage.setItem('token', data.token);
        setIsAuthenticated(true);
        setError([]); // Clear errors on successful login
      } else {
        // Handle errors
        if (data.errors && data.errors.length > 0) {
          setError(data.errors); // Store API error messages
        } else {
          setError([{ msg: 'Login failed. Please try again.' }]); // Fallback error
        }
      }
    } catch (err) {
      setError([{ msg: 'An unexpected error occurred. Please try again later.' }]);
    } finally {
      setLoading(false);
    }
  };

  const merchantlogout = () => {
    localStorage.removeItem("merchantData");
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = "/merchantlogin"; 
    console.log("This is merchant");// Adjust the URL path as per your app's routing structure
  };

  let timeoutId;

  const startLogoutTimer = () => {
    clearTimeout(timeoutId); // Clear any existing timer
    timeoutId = setTimeout(() => {
      merchantlogout();
      window.location.href = "/merchantlogin";  // Call the logout function after 10 minutes of inactivity
    }, 10 * 60 * 1000);
  };

  // Reset the inactivity timer on user activity
  const resetTimer = () => {
    startLogoutTimer(); // Start the logout timer
  };

  // Attach event listeners to detect user activity
  const setupActivityListeners = () => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);
  };

  const initializeInactivityLogout = () => {
    setupActivityListeners();
    startLogoutTimer(); // Start the logout timer when the app starts
  };

  useEffect(() => {
    initializeInactivityLogout();

    return () => {
      // Clean-up: Remove event listeners when the component unmounts
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts
    };
  }, [isSaved, currentPage]);

  return (
    <AuthContext.Provider value={{
      handleSubmit,
      isAuthenticated,
      merchantlogout,
      setIsSaved,
      error,
      loading,
      totalPages
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
