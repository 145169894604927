import React from "react";
import logo from "../../images/logo/logo.png";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../merchant/component/AuthContext";
import { MdSpaceDashboard } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { BsBank2 } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { BiSolidLogOutCircle } from "react-icons/bi";

const SiderBar = () => {
    const { merchantlogout } = useAuth();
    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className="nav-link " to="/merchant/merchantdashboard" >
                        <MdSpaceDashboard />
                        <span> Dashboard</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" to="">
                        <AiFillProduct /><span>Merchant Product</span><i className="bi bi-chevron-down ms-auto"></i>
                    </Link>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/merchant/merchantproductlist">
                                <i className="bi bi-circle"></i><span>All Product</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                            <i className="bi bi-circle"></i><span>Add New Products</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="nav-item">
                    <Link className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" to="">
                        <BsBank2 /><span>Merchant Bank</span><i className="bi bi-chevron-down ms-auto"></i>
                    </Link>
                    <ul id="forms-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/merchant/merchantbank">
                            <i className="bi bi-circle"></i><span>All Bank</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                            <i className="bi bi-circle"></i><span>Add Bank</span>
                            </Link>
                        </li>
                    </ul>
                </li>


                <li className="nav-item">
                    <Link className="nav-link collapsed" to="/merchant/merchantdetails">
                        <FaUserTie />
                        <span>Profile</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link collapsed" to="" onClick={() => merchantlogout()}>
                        <BiSolidLogOutCircle />
                        <span>Log Out</span>
                    </Link>
                </li>
            </ul>
        </aside>
    );
}


export default SiderBar
                            