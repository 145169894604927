import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../component/Sidebar";
import Header from "../component/Header";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate, useParams , useLocation, Link } from 'react-router-dom';
import { useAuth } from "../component/AuthContext";
import SweetAlertComponent from "../utils/alert";


const EditCategories = () => {
    // const { id } = useParams
    const navigate = useNavigate();
    const [name, setName] = useState({});
    const [status, setStatus] = useState({});
    const [ message, setMessage] = useState('');
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    useEffect(() => {
        // Fetch the category data
        const fetchCategory = async () => {
            try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/category/${id}`);
            const data = await response.json();
            if (response.ok) {
              setName(data.name);
              setStatus(data.status);
            } 
            } catch (error) {
            setMessage(`Error: ${error.message}`);
          }
        };
    
        fetchCategory();
    }, [id]);

    const handleSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
        const updatedCategory = { name, status };
    
        try {
          const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/category/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedCategory),
          });
    
          const data = await response.json();
    
          if (response.ok) {
            sweetAlertClass.showSweetAlert(1, "", "Categories Updated Successfully");
            navigate('/dashboard/catergorieslist');
          } else {
            sweetAlertClass.showSweetAlert(2, "", "Error Occurred While Updating Categories");
          }
        } catch (error) {
          setMessage(`Error: ${error.message}`);
        }
    };

  return (
    <div>
      <div id="wrapper">
        <div id="page" className="">
          <div className="layout-wrap">
            
            <div className="section-content-right">
              <div className="main-content">
                <div className="main-content-inner">
                  <div className="main-content-wrap">
                    <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                      <h3> Update Category infomation</h3>
                      <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                        <li>
                          <Link to="/" style={{ textDecoration: "none" }}><div className="text-tiny">Dashboard</div></Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <Link to="#" style={{ textDecoration: "none" }}><div className="text-tiny">Category</div></Link>
                        </li>
                        <li>
                          <FaAngleRight />
                        </li>
                        <li>
                          <div className="text-tiny">Update category</div>
                        </li>
                      </ul>
                    </div>
                    <div className="wg-box">
                      <form className="form-new-product form-style-1" onSubmit={handleSubmit} >
                        <fieldset className="name">
                          <div className="body-title">Category name <span className="tf-color-1">*</span></div>
                          <input type="text" placeholder="Catergory Name" value={name} onChange={(e) => setName(e.target.value)} className="" name="name" tabIndex="2"  aria-required="true" required="" />
                        </fieldset>
                        <fieldset className="status">
                          <div className="body-title"> Status <span className="tf-color-1">*</span> </div>
                            <select value={status} onChange={(e) => setStatus(e.target.value)} required>
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </fieldset>
                        <div className="bot">
                          <div></div>
                          <button className="tf-button w208" type="submit">Update Category</button>
                          <button onClick={() => navigate('/catergorieslist')} className="tf-button w208" type="submit">Cancel Updated</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bottom-page">
                  <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategories;
