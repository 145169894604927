import React, { useState } from "react";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../component/AuthContext";
import { Link } from "react-router-dom";
import NoAdminImage from '../../images/logo/logo.png';
import { useNavigate } from "react-router-dom";
import { RxEyeOpen } from "react-icons/rx";
import { GoEyeClosed } from "react-icons/go";

const MerchantLogin = () => {
    const { handleSubmit, error } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false); 
  
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  
    const onSubmit = async (e) => {
        e.preventDefault();
        await handleSubmit(e);
        
        const adminData = JSON.parse(localStorage.getItem('adminData'));
        const vendorLevel = adminData ? adminData.level.name : null;

        navigate('/merchant/merchantdashboard'); 
    };

    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center py-4">
                                <Link to="" className="logo d-flex align-items-center w-auto">
                                    <img src={NoAdminImage} alt="" className="large-image" />
                                </Link>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <p className="text-center run">Log in to your Merchant Account</p>
                                    </div>
                                    {error && error.length > 0 && (
                                        <div className="alert alert-danger">
                                            <ul>
                                                {error.map((err, index) => (
                                                    <li key={index}>{err.msg}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    <form onSubmit={onSubmit} className="row g-3">
                                        <div className="col-12">
                                            <label for="yourEmail" className="form-label runs">Merchant Email</label>
                                            <input onChange={(e) => setEmail(e.target.value)} type="email" value={email} name="email" className="form-control" id="yourEmail" required/>
                                        </div>

                                        {/* <div className="col-12">
                                            <label for="yourPassword" className="form-label runs">Password</label>
                                            <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" name="password" className="form-control" id="yourPassword" required/>
                                        </div> */}

                                        <div className="col-12 position-relative">
                                            <label htmlFor="password" className="form-label runs">Password</label>
                                            <input type={showPassword ? "text" : "password"} name="password" className="form-control" id="yourPassword"  onChange={(e) => setPassword(e.target.value)} value={password}  required />
                                            <span className="password-toggle-icon" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '20px', top: '40px', cursor: 'pointer', fontSize: '1.9rem'}}>
                                                {showPassword ? <GoEyeClosed /> : <RxEyeOpen />}
                                            </span>
                                        </div>

                                        <div className="col-12">
                                            <button className="runned" type="submit">Login</button>
                                        </div>

                                        <div className="col-12 last-run">
                                            <p className="small mb-0">Don't Have an Account?  
                                                <Link to="/merchantsignup"> Create your Merchant Account Here</Link>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="credits">Developed by <Link to="">Aino-Tech Global</Link> </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MerchantLogin;
