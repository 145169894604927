import React from "react";
import Header from "../component/Header";
import SideBar from "../../component/Sidebar";
import NoAdminImage from '../../images/avatar/avatar.png';
import { Link, useNavigate } from "react-router-dom";
const MerchantDetails = () => {
  return (
        <main id="main" className="main">
            <div className="pagetitle">
            <h1>Profile</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="">DashBoard</Link></li>
                        <li className="breadcrumb-item active">Merchant Profile</li>
                    </ol>
                </nav>
            </div>
            <section className="section profile">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <img src={NoAdminImage} alt="Profile" className="rounded-circle"/>
                                <h2>Kevin Anderson</h2>
                                <h3>Web Designer</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body pt-3">
                                <ul className="nav nav-tabs nav-tabs-bordered">

                                    <li className="nav-item">
                                    <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                                    </li>

                                    <li className="nav-item">
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                                    </li>


                                    <li className="nav-item">
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                                    </li>

                                </ul>
                                <div className="tab-content pt-2">
                                    <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                        <h5 className="card-title">Profile Details</h5>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Full Name</div>
                                            <div className="col-lg-9 col-md-8">Kevin Anderson</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Store Name</div>
                                            <div className="col-lg-9 col-md-8">Lueilwitz, Wisoky and Leuschke</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Store Description</div>
                                            <div className="col-lg-9 col-md-8">A108 Adam Street, New York, NY 535022</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Phone</div>
                                            <div className="col-lg-9 col-md-8">(436) 486-3538 x29071</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Email</div>
                                            <div className="col-lg-9 col-md-8">k.anderson@example.com</div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                                        <form>
                                            <div className="row mb-3">
                                            <label for="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <img src={NoAdminImage} alt="Profile"/>
                                                    <div className="pt-2">
                                                        <a href="#" className="btn btn-primary btn-sm" title="Upload new profile image"><i className="bi bi-upload"></i></a>
                                                        <a href="#" className="btn btn-danger btn-sm" title="Remove my profile image"><i className="bi bi-trash"></i></a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label for="fullName" className="col-md-4 col-lg-3 col-form-label">First Name</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="First Name" type="text" className="form-control" id="firstName" value=""/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label for="fullName" className="col-md-4 col-lg-3 col-form-label">Last Name</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="Last Name" type="text" className="form-control" id="firstName" value=""/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label for="company" className="col-md-4 col-lg-3 col-form-label">Store Name</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="Store Name" type="text" className="form-control" id="company" value=""/>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label for="about" className="col-md-4 col-lg-3 col-form-label">Store Description</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <textarea name="about" className="form-control" id="about" style={{height: "100px"}}></textarea>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary">Save Changes</button>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="tab-pane fade pt-3" id="profile-change-password">
                                        <form>
                                            <div className="row mb-3">
                                                <label for="currentPassword" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="password" type="password" className="form-control" id="currentPassword"/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label for="newPassword" className="col-md-4 col-lg-3 col-form-label">New Password</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input name="newpassword" type="password" className="form-control" id="newPassword"/>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary">Change Password</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MerchantDetails;
