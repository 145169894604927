import React, { useState, useEffect } from "react";
import { useAuth } from "../component/AuthContext";
import SideBar from "../component/Sidebar";
import Header from "../component/Header";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';
import SweetAlertComponent from "../utils/alert";

const EditAdmin = () => {
    const { admin} = useAuth();
    const { role} = useAuth();
    const navigate = useNavigate();
    const [ message, setMessage] = useState('');
    const location = useLocation();
    const { setIsSaved } = useAuth();
    const { id } = useParams();
    const [adminDetails, setAdminDetails] = useState({
        firstName: '',
        lastName: '',
        level: ''
    });

    useEffect(() => {
        // Fetch admin details
        const fetchAdminDetails = async () => {
          try {
            const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/${id}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();

            setAdminDetails({
              firstName: data.firstName,
              lastName: data.lastName,
              level: data.level.name,
            });
            
          } catch (error) {
            setMessage(`Error: ${error.message}`);
          }
        };
    
        fetchAdminDetails();
    }, [id]);

    const handleChange = (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        const { name, value } = e.target;
        setAdminDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        let sweetAlertClass = new SweetAlertComponent();
        e.preventDefault();
        try {
          // Update admin details
          const token = localStorage.getItem('token');
          const response = await fetch(`https://aino-tech-server.onrender.com/api/v1/admin/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(adminDetails)
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          setIsSaved(true);
          sweetAlertClass.showSweetAlert(1, "", "Admin Information Updated");
          navigate('/dashboard/admin');
        } catch (error) {
          console.error("Error updating admin details", error);
          sweetAlertClass.showSweetAlert(2, "", "Error While Updating Admin Information");
        }
    };


    return (
        <div>
          <div id="wrapper">
            <div id="page" className="">
              <div className="layout-wrap">
                
                <div className="section-content-right">
                  <div className="main-content">
                    <div className="main-content-inner">
                      <div className="main-content-wrap">
                        <div className="flex items-center flex-wrap justify-between gap20 mb-27">
                          <h3> Update Admin infomation</h3>
                          <ul className="breadcrumbs flex items-center flex-wrap justify-start gap10">
                            <li>
                              <Link to="/" style={{ textDecoration: "none" }}><div className="text-tiny">Dashboard</div></Link>
                            </li>
                            <li>
                              <FaAngleRight />
                            </li>
                            <li>
                              <Link to="#" style={{ textDecoration: "none" }}><div className="text-tiny">Admin</div></Link>
                            </li>
                            <li>
                              <FaAngleRight />
                            </li>
                            <li>
                              <div className="text-tiny">Update Admin</div>
                            </li>
                          </ul>
                        </div>
                        <div className="wg-box">
                        <form className="form-new-product form-style-1" onSubmit={handleSubmit}>
                        <fieldset className="name">
                          <div className="body-title">Admin First Name <span className="tf-color-1">*</span></div>
                          <input type="text" placeholder="First Name" value={adminDetails.firstName} onChange={handleChange} className="" name="firstName" tabIndex="2" aria-required="true" required="" />
                        </fieldset>
                        <fieldset className="name">
                          <div className="body-title">Admin Last Name <span className="tf-color-1">*</span></div>
                          <input type="text" placeholder="Last Name" value={adminDetails.lastName} onChange={handleChange} className="" name="lastName" tabIndex="2" aria-required="true" required="" />
                        </fieldset>
                        <fieldset className="name mb-24">
                            <div className="body-title mb-10">Level</div>
                            <select className="flex-grow" name="level" value={adminDetails.level} onChange={handleChange} aria-required="true" required>
                                <option value="" disabled>Select Role</option>
                                {role.map(r => (
                                    <option key={r._id} value={r._id}>{r.name}</option>
                                ))}
                            </select>
                        </fieldset>
                        <div className="bot">
                          <div></div>
                          <button className="tf-button w208" type="submit">Update Admin</button>
                          <button onClick={() => navigate('/dashboard/admin')} className="tf-button w208" type="button">Cancel Update</button>
                        </div>
                      </form>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-page">
                      <div className="body-text">Copyright © 2024 Aino Tech Global.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}
 
export default EditAdmin;