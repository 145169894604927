import React from "react";
import logo from "../images/logo/logo.png";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdGridView } from "react-icons/md";
import { MdOutlineShoppingCart } from "react-icons/md";
import { PiFilePlusLight } from "react-icons/pi";
import { LuUserPlus } from "react-icons/lu";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { SlLayers } from "react-icons/sl";
import { CiLogout } from "react-icons/ci";
import { useAuth } from "./AuthContext";
import { Link, NavLink } from "react-router-dom";

const SideBar = () => {
    const { logout } = useAuth();
    const adminData = JSON.parse(localStorage.getItem('adminData'));
    const isMerchant = adminData && adminData.level?.name.toLowerCase() === 'merchant';
    const isSuperAdmin = adminData && adminData.level?.name.toLowerCase() === 'super admin';
    const isAdmin = adminData && adminData.level?.name.toLowerCase() === 'admin';

    return (
        <>
            <div className="section-menu-left">
                <div className="box-logo">
                    <Link to="/dashboard">
                        <img src={logo} alt="" id="logo_header_mobile" />
                    </Link>
                    <div className="button-show-hide">
                        <MdOutlineKeyboardDoubleArrowLeft />
                    </div>
                </div>
                {/* Merchant Side bar */}
                <div className="center">
                    {/* Super Admin Sidebar */}
                    <div className="center-item">
                        <ul className="menu-list">
                            {isSuperAdmin && (
                                <>

                                    <li className="menu-item">
                                        <Link to="/dashboard" >
                                            <div className="icon">
                                                <MdGridView />
                                            </div>
                                            <div className="text">aino Dashboard</div>
                                        </Link>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <LuUserPlus />
                                            </div>
                                            <div className="text">Admin</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <NavLink to="/dashboard/admin" >
                                                    <div className="text">All Admin</div>
                                                </NavLink>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/addadmin" >
                                                    <div className="text">Add new Admin</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <LuUserPlus />
                                            </div>
                                            <div className="text">User</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/user" >
                                                    <div className="text">All user</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <LuUserPlus />
                                            </div>
                                            <div className="text">Merchant</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/merchant" >
                                                    <div className="text">All Merchant</div>
                                                </Link>
                                            </li>
                                        </ul>                                        
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <LuUserPlus />
                                            </div>
                                            <div className="text">Aino Influencer</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/influencer" >
                                                    <div className="text">Aino Influencer</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <AiOutlineUser />
                                            </div>
                                            <div className="text">Roles</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/allroles" >
                                                    <div className="text">All roles</div>
                                                </Link>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/createroles" >
                                                    <div className="text">Create role</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <SlLayers />
                                            </div>
                                            <div className="text">Aino Banner</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/advert" >
                                                    <div className="text">Aino Banner list</div>
                                                </Link>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/addadvert" >
                                                    <div className="text">Create New Banner</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <SlLayers />
                                            </div>
                                            <div className="text">Categories</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/catergorieslist" >
                                                    <div className="text">Categories list</div>
                                                </Link>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/newcatergories" >
                                                    <div className="text">Create New Categories</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <SlLayers />
                                            </div>
                                            <div className="text">Sub Categories</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/subcategories" >
                                                    <div className="text">Sub Categories list</div>
                                                </Link>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/NewSubCategories" >
                                                    <div className="text">Create New Sub Categories</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <MdOutlineShoppingCart />
                                            </div>
                                            <div className="text">Inventory</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/productlist" >
                                                    <div className="text">Inventory List</div>
                                                </Link>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/addproduct" >
                                                    <div className="text">Add Inventory</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <PiFilePlusLight />
                                            </div>
                                            <div className="text">Order</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/orderlist" >
                                                    <div className="text">Order list</div>
                                                </Link>
                                            </li>
                                            
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <MdOutlineShoppingCart />
                                            </div>
                                            <div className="text">FlashSales</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/flashsales" >
                                                    <div className="text">Flash Sales List</div>
                                                </Link>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/addFlashSales" >
                                                    <div className="text">Create Flash Sales</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()}className="menu-item-button" >
                                            <div className="icon">
                                                <PiFilePlusLight />
                                            </div>
                                            <div className="text">Transactions</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/transcationlist" >
                                                    <div className="text">All Transactions</div>
                                                </Link>
                                            </li>
                                            
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <SlLayers />
                                            </div>
                                            <div className="text">Pick up Location</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/pickupstationlist" >
                                                    <div className="text">Pick Up Location list</div>
                                                </Link>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/newpickuplist" >
                                                    <div className="text">Create New Location</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="menu-item has-children">
                                        <Link to="#" onClick={(e) => e.preventDefault()} className="menu-item-button">
                                            <div className="icon">
                                                <SlLayers />
                                            </div>
                                            <div className="text">Voucher Codes</div>
                                        </Link>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/vouchercodelist" >
                                                    <div className="text">Voucher Codes list</div>
                                                </Link>
                                            </li>
                                            <li className="sub-menu-item">
                                                <Link to="/dashboard/newvouchercode" >
                                                    <div className="text">Create New Voucher Code</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </>
                            )}
                            
                            {/* Common for all users */}
                            <li className="menu-item">
                                <Link to="#" onClick={logout}>
                                    <div className="icon">
                                        <CiLogout />
                                    </div>
                                    <div className="text">Logout</div>
                                </Link>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
        </>
    );
};

export default SideBar;
